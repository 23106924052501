<template>
  <button
    class="transition-opacity text-base flex items-center focus:outline-none focus-visible:outline-none select-none"
    @click="onClick"
    name="Botón login"
    aria-label="Botón login"
  >
    <div class="flex items-center" v-if="userAutenticado">
      <Avatar class="w-10 rounded-full mr-2" :user="user" />
      <div class="text-left">
        <p class="md:inline-block md:text-sm leading-4">
          ¡<span
            class="max-w-24 overflow-hidden inline-block truncate align-middle xl:max-w-48"
            >{{ textoLoginButton }}</span
          >!
        </p>
        <div class="text-xs leading-4 font-normal flex items-center">
          <BananaIcon class="w-5 h-5 mr-1 md:hidden xl:block" />
          <span class="md:hidden xl:inline-block mr-1"
            >{{ puntosComputados }} bananas ·</span
          >
          Nivel {{ nivelComputado }}
        </div>
      </div>
    </div>
    <div class="flex items-center" v-else>
      <UserIcon class="w-7 h-7 mr-2 text-green-500" />
      <span
        class="md:underline underline-offset-4 md:ml-2 md:text-sm py-2 md:py-0"
        >Iniciar sesión/regístrate</span
      >
    </div>
    <ChevronDownIcon class="w-5 h-5 ml-3 hidden xl:block" />
  </button>
</template>
<script setup lang="ts">
  import {
    abrirModalDespachoLogin,
    setearComponenteActivoModalDespachoLogin,
    setearTabRegistroLoginModalDespacho,
  } from "@stores/despacho";
  import { useStore } from "@nanostores/vue";
  import { user as storeUser } from "@stores/user";
  import { direccionActiva as storeDireccion } from "@stores/despacho";
  import { ref, onMounted, computed } from "@vue/runtime-core";
  import type { User } from "@lib/interfaces";
  import { UserIcon } from "@heroicons/vue/24/outline";
  import BananaIcon from "@components/iconos/BananaIcon.vue";
  import { ChevronDownIcon } from "@heroicons/vue/24/outline";
  import Avatar from "@components/cuenta/Avatar.vue";

  interface Props {
    user?: User | null;
  }
  const props = defineProps<Props>();
  const $user = useStore(storeUser);
  const $direccion = useStore(storeDireccion);
  const isServer = ref(true);

  const userAutenticado = computed(() => {
    return props.user != null || (!isServer.value && $user.value != null);
  });

  const textoLoginButton = computed(() => {
    if (isServer.value) {
      return props.user?.nombre ? "Hola " + props.user.nombre : "Hola";
    }
    return $user.value?.nombre ? "Hola " + $user.value.nombre : "Hola";
  });

  const puntosComputados = computed(() => {
    if (isServer.value) {
      return props.user?.saldo_puntos;
    }
    return $user.value?.saldo_puntos;
  });

  const nivelComputado = computed(() => {
    if (isServer.value) {
      return props.user?.nivel.nombre;
    }
    return $user.value?.nivel.nombre;
  });

  const onClick = () => {
    if (window.location.pathname === "/cuenta") {
      return;
    }

    if (userAutenticado.value) {
      return (window.location.href = "/cuenta");
    }
    if (!userAutenticado.value && $direccion.value) {
      setearTabRegistroLoginModalDespacho("login");
      setearComponenteActivoModalDespachoLogin("RegistroLogin");
      abrirModalDespachoLogin();
      return;
    }
    setearComponenteActivoModalDespachoLogin("SeleccionarDespacho");
    abrirModalDespachoLogin();
  };

  onMounted(() => {
    isServer.value = false;
  });
</script>
